import {
  Location,
  CartesianLine,
  CartesianPlannerRoutePoint,
  CartesianPoint,
  CartesianPolygonRepresentation,
  CartesianPolygonVertex,
  MeshLine,
  MissionPlannerCandidateParameters,
  CartesianMissionPlannerRoute,
  MissionPlannerRoute,
  PlannerRoutePoint,
} from "./types";
import * as geolib from "geolib";

const defaultHomePointDistanceToPolygon = 5;

export const distanceBetweenLocations = (a: Location, b: Location): number => {
  return geolib.getDistance(a, b, 0.01);
};

export const bearingBetweenLocations = (a: Location, b: Location): number => {
  return geolib.getRhumbLineBearing(a, b);
};

/**
 * Procedure to create a cartesian representation of a geographic polygon given us the possibility
 * to do the computations in a cartesian space
 *
 * O(n) procedure
 * @param polygon Geographic polygon
 * @param homePoint Home point info to also be converted if existent
 */
export const createCartesianPolygonRepresentation = (
  polygon: Location[],
  homePoint: Location | null,
  pointToCreateHomePoint: Location | null
): CartesianPolygonRepresentation => {
  const basePoint = polygon[0];

  const getLocationCartesianPoint = (location: Location): CartesianPoint => {
    const distance = distanceBetweenLocations(basePoint, location);
    const bearing = bearingBetweenLocations(basePoint, location);

    const angle = convertBearingToAngle(bearing);
    const radiansAngle = angleToRadians(angle);

    const x = Math.cos(radiansAngle) * distance;
    const y = Math.sin(radiansAngle) * distance;

    return {
      x: x,
      y: y,
    };
  };

  let polygonCartesianPoints: CartesianPoint[] = [];

  for (let i = 0; i < polygon.length; i++) {
    const vertex = polygon[i];

    const point = getLocationCartesianPoint(vertex);

    if (polygonCartesianPoints.length > 0) {
      const lastPoint = polygonCartesianPoints[polygonCartesianPoints.length - 1];

      if (lastPoint.x === point.x && lastPoint.y === point.y) {
        continue;
      }
    }

    polygonCartesianPoints.push(point);
  }

  polygonCartesianPoints = polygonVerticesAsClockwise(polygonCartesianPoints);

  const area = polygonArea(polygonCartesianPoints);

  const cartesianPolygon = polygonCartesianPoints.map((point, index) => {
    const nextPoint = polygonCartesianPoints[(index + 1) % polygonCartesianPoints.length];

    const line = createCartesianPointsLineEquation(point, nextPoint);

    return {
      point: point,
      line: line,
      angle: getCartesianLineAngle(line),
      length: distanceBetweenPoints(point, nextPoint),
    };
  });

  let homePointPoint: CartesianPoint | null;
  let homePointDistanceToPolygon: number | null;
  let homePointPolygonNearbyPoint: CartesianPoint;
  let homePointPolygonNearbySide: number;

  if (homePoint !== null) {
    homePointPoint = getLocationCartesianPoint(homePoint);
    const homePointNearbyPointAndDistance = getPointNearbyPolygonPointDistanceAndSide(
      homePointPoint,
      polygonCartesianPoints
    );

    homePointDistanceToPolygon = homePointNearbyPointAndDistance.distance;
    homePointPolygonNearbyPoint = homePointNearbyPointAndDistance.nearbyPoint;
    homePointPolygonNearbySide = homePointNearbyPointAndDistance.side;
  } else if (pointToCreateHomePoint !== null) {
    homePointPoint = null;
    homePointDistanceToPolygon = null;

    const pointToCreateHomePointPoint = getLocationCartesianPoint(pointToCreateHomePoint);

    const pointToCreateHomePointNearbyPointAndDistance = getPointNearbyPolygonPointDistanceAndSide(
      pointToCreateHomePointPoint,
      polygonCartesianPoints
    );

    homePointPolygonNearbyPoint = pointToCreateHomePointNearbyPointAndDistance.nearbyPoint;
    homePointPolygonNearbySide = pointToCreateHomePointNearbyPointAndDistance.side;
  } else {
    return {
      basePoint: basePoint,
      cartesianPolygon: cartesianPolygon,
      polygonArea: area,
      homePoint: null,
    };
  }

  return {
    basePoint: basePoint,
    cartesianPolygon: cartesianPolygon,
    polygonArea: area,
    homePoint: {
      point: homePointPoint,
      distanceToPolygon: homePointDistanceToPolygon,
      polygonNearbyPoint: homePointPolygonNearbyPoint,
      polygonNearbySide: homePointPolygonNearbySide,
    },
  };
};

/**
 * Convert cartesian points to geographic locations
 *
 * O(n) procedure
 *
 * That method will process cartesian points created using a reference @param basePoint and
 * convert those to the corresponding geographic coordinates
 * @param basePoint Base point to create a cartesian representation
 * @param cartesianPoints Points to be converted to geographic ones using that base point
 */
export const convertCartesianPointsToGeographicLocations = (
  basePoint: Location,
  cartesianPoints: CartesianPoint[]
): Location[] => {
  const result: Location[] = [];

  for (const cartesianPoint of cartesianPoints) {
    const distance = Math.sqrt(Math.pow(cartesianPoint.x, 2) + Math.pow(cartesianPoint.y, 2));

    if (distance === 0) {
      result.push(basePoint);
      continue;
    }

    const radiansUnsignedAngle = Math.atan(Math.abs(cartesianPoint.y) / Math.abs(cartesianPoint.x));
    const unsignedAngle = radiansAngleToAngle(radiansUnsignedAngle);

    /// Angle between [-180, 180]
    let angle: number;
    if (cartesianPoint.x >= 0 && cartesianPoint.y >= 0) {
      angle = unsignedAngle;
    } else if (cartesianPoint.x >= 0) {
      angle = -unsignedAngle;
    } else if (cartesianPoint.y >= 0) {
      angle = 180 - unsignedAngle;
    } else {
      angle = -180 + unsignedAngle;
    }

    const bearing = convertAngleToBearing(angle);

    const location = castGeolibPointToLocation(
      geolib.computeDestinationPoint(basePoint, distance, bearing)
    );

    result.push(location);
  }

  return result;
};

/**
 * Procedure to apply a padding in a cartesian polygon representation
 *
 * O(n^2) procedure
 * @param cartesianPolygonRepresentation cartesian polygon info
 * @param padding distance to apply inset in the polygon
 */
/// That method will apply padding in each polygon side considering the pair of consecutive vertexes
/// as a side considering two situations:
///   - Segment with intersection: [package/documentation/images/padding_polygon_padding_sides_with_intercession.png]
///     In  that case we only have to put the intersection as a new polygon vertex
///   - Segment without  intersection: [package/documentation/images/padding_polygon_padding_sides_without_intercession.png]
///     In  that case we will use the padding side segments and the original vertex with an applied padding
/// To finish that process we need to remove the self intersection cases like we can see in the example:
///   Self intersect case: [package/documentation/images/padding _polygon_self_intercession_sides.png]
export const applyPaddingInCartesianPolygon = (
  cartesianPolygonRepresentation: CartesianPolygonRepresentation,
  padding: number
): CartesianPolygonRepresentation => {
  const newPolygonPoints = _applyPaddingInPolygonPoints(
    cartesianPolygonRepresentation.cartesianPolygon.map((e) => e.point),
    padding,
    true
  );

  return {
    basePoint: cartesianPolygonRepresentation.basePoint,
    cartesianPolygon: newPolygonPoints.map((point, index) => {
      const nextPoint = newPolygonPoints[(index + 1) % newPolygonPoints.length];
      const line = createCartesianPointsLineEquation(point, nextPoint);

      return {
        point: point,
        line: line,
        angle: getCartesianLineAngle(line),
        length: distanceBetweenPoints(point, nextPoint),
      };
    }),
    polygonArea: polygonArea(newPolygonPoints),
    homePoint:
      cartesianPolygonRepresentation.homePoint !== null
        ? {
            ...cartesianPolygonRepresentation.homePoint,
            polygonNearbySide: getPointNearbyPolygonPointDistanceAndSide(
              cartesianPolygonRepresentation.homePoint.polygonNearbyPoint,
              newPolygonPoints
            ).side,
          }
        : null,
  };
};

const _applyPaddingInPolygonPoints = (
  polygonPoints: CartesianPoint[],
  padding: number,
  canChangeAmountOfVertexes: boolean
): CartesianPoint[] => {
  const originalPolygonPaddingSides: {
    initial: CartesianPoint;
    final: CartesianPoint;
    initialBasePoint: CartesianPoint;
  }[] = [];

  for (let i = 0; i < polygonPoints.length; i++) {
    const initialBasePoint = polygonPoints[i];
    const finalBasePoint = polygonPoints[(i + 1) % polygonPoints.length];
    originalPolygonPaddingSides.push({
      ...applyPaddingInPolygonSide(initialBasePoint, finalBasePoint, padding, polygonPoints),
      initialBasePoint: initialBasePoint,
    });
  }

  const newPolygonPoints: CartesianPoint[] = [];

  for (let i = 0; i < originalPolygonPaddingSides.length; i++) {
    const previousSide =
      originalPolygonPaddingSides[circularIndex(i - 1, originalPolygonPaddingSides.length)];
    const side = originalPolygonPaddingSides[i];

    const intersection = findIntersectionBetweenSegments(
      {
        p1: previousSide.initial,
        p2: previousSide.final,
      },
      {
        p1: side.initial,
        p2: side.final,
      }
    );

    if (intersection === undefined) {
      if (!canChangeAmountOfVertexes) {
        newPolygonPoints.push(
          findSegmentsProjectionIntersection(
            {
              p1: previousSide.initial,
              p2: previousSide.final,
            },
            {
              p1: side.initial,
              p2: side.final,
            }
          )
        );
      } else {
        newPolygonPoints.push(previousSide.final);

        const pointToApplyPadding = side.initialBasePoint;

        const oppositePaddingDirectionPaddingVector: Vector = {
          deltaX: side.initial.x - previousSide.final.x,
          deltaY: side.initial.y - previousSide.final.y,
        };

        let paddingVectorDirection = rotate90PositiveDegreesOnVector(
          oppositePaddingDirectionPaddingVector
        );
        const paddingVectorNorm = vectorNorm(paddingVectorDirection);
        paddingVectorDirection = {
          deltaX: (padding * paddingVectorDirection.deltaX) / paddingVectorNorm,
          deltaY: (padding * paddingVectorDirection.deltaY) / paddingVectorNorm,
        };

        let paddingPoint: CartesianPoint = {
          x: pointToApplyPadding.x + paddingVectorDirection.deltaX,
          y: pointToApplyPadding.y + paddingVectorDirection.deltaY,
        };
        if (!checkPointInsidePolygon(paddingPoint, polygonPoints)) {
          paddingPoint = {
            x: pointToApplyPadding.x - paddingVectorDirection.deltaX,
            y: pointToApplyPadding.y - paddingVectorDirection.deltaY,
          };
        }

        newPolygonPoints.push(paddingPoint);

        newPolygonPoints.push(side.initial);
      }
    } else {
      newPolygonPoints.push(intersection);
    }
  }

  if (!canChangeAmountOfVertexes) return newPolygonPoints;

  return removePolygonSelfIntersections(newPolygonPoints);
};

const findSegmentsProjectionIntersection = (a: Segment, b: Segment): CartesianPoint => {
  const x1 = a.p1.x;
  const y1 = a.p1.y;
  const x2 = a.p2.x;
  const y2 = a.p2.y;
  const x3 = b.p1.x;
  const y3 = b.p1.y;
  const x4 = b.p2.x;
  const y4 = b.p2.y;

  const alphaBetaDenominator = (x4 - x3) * (y2 - y1) - (y4 - y3) * (x2 - x1);
  if (alphaBetaDenominator === 0) {
    return {
      x: (a.p2.x + b.p2.x) / 2,
      y: (a.p2.y + b.p2.y) / 2,
    };
  }

  const alpha = ((x4 - x3) * (y3 - y1) - (y4 - y3) * (x3 - x1)) / alphaBetaDenominator;

  return {
    x: x1 + alpha * (x2 - x1),
    y: y1 + alpha * (y2 - y1),
  };
};

const removePolygonSelfIntersections = (points: CartesianPoint[]): CartesianPoint[] => {
  let firstSideWithSelfIntersection:
    | {
        index: number;
        intersection: {
          point: CartesianPoint;
          side: number;
        };
      }
    | undefined;

  for (let i = 0; i < Math.round(points.length / 2); i++) {
    const side: Segment = {
      p1: points[i],
      p2: points[(i + 1) % points.length],
    };

    let furtherSideIntersection:
      | {
          point: CartesianPoint;
          side: number;
        }
      | undefined = undefined;

    for (
      let j = (i + 2) % points.length;
      j !== circularIndex(i - 1, points.length);
      j = (j + 1) % points.length
    ) {
      const compareSide: Segment = {
        p1: points[j],
        p2: points[(j + 1) % points.length],
      };

      const intersection = findIntersectionBetweenSegments(side, compareSide);

      if (intersection !== undefined) {
        furtherSideIntersection = {
          point: intersection,
          side: j,
        };
      }
    }

    if (furtherSideIntersection !== undefined) {
      firstSideWithSelfIntersection = {
        index: i,
        intersection: furtherSideIntersection,
      };
    }

    if (firstSideWithSelfIntersection !== undefined) break;
  }

  if (firstSideWithSelfIntersection === undefined) return points;

  /// Using the interception point we have two options
  /// or the result polygon begins in the interception point
  /// or the that point replace a segment of points

  const firstPointsOption: CartesianPoint[] = [];
  const secondPointsOption: CartesianPoint[] = [];

  for (let i = 0; i <= firstSideWithSelfIntersection.index; i++) {
    firstPointsOption.push(points[i]);
  }
  firstPointsOption.push(firstSideWithSelfIntersection.intersection.point);
  secondPointsOption.push(firstSideWithSelfIntersection.intersection.point);
  for (
    let i = firstSideWithSelfIntersection.index + 1;
    i <= firstSideWithSelfIntersection.intersection.side;
    i++
  ) {
    secondPointsOption.push(points[i]);
  }
  for (let i = firstSideWithSelfIntersection.intersection.side + 1; i < points.length; i++) {
    firstPointsOption.push(points[i]);
  }

  const firstResultPolygon = removePolygonSelfIntersections(firstPointsOption);
  const secondResultPolygon = removePolygonSelfIntersections(secondPointsOption);

  if (polygonArea(firstResultPolygon) >= polygonArea(secondResultPolygon)) {
    return firstResultPolygon;
  }

  return secondResultPolygon;
};

/**
 * Procedure to check if that polygon exists
 *
 * That method will check if we don't have a self-intersection polygon
 *
 * O(n^2) procedure
 * @param cartesianPolygon cartesian polygon info
 */
export const checkCartesianPolygonExists = (cartesianPolygon: CartesianPoint[]): boolean => {
  for (let i = 0; i < cartesianPolygon.length; i++) {
    const segment: Segment = {
      p1: cartesianPolygon[i],
      p2: cartesianPolygon[(i + 1) % cartesianPolygon.length],
    };

    for (let j = 0; j < cartesianPolygon.length; j++) {
      if (j === i) continue;

      const testSegment: Segment = {
        p1: cartesianPolygon[j],
        p2: cartesianPolygon[(j + 1) % cartesianPolygon.length],
      };

      if (testSegment === segment) return false;

      if (checkSegmentIntersection(segment, testSegment)) {
        return false;
      }
    }
  }

  return true;
};

/**
 * Get mission planner candidates parameters to explore to find mission planner routes
 *
 * O(n) procedure
 *
 * In that first implementation we create candidates like that:
 *    - Using polygon angle tendency and the vertex with an angle closer than that value
 *    - Using polygon angle tendency and the closer coordinates from polygon bounding box and the
 *    direction angle of that angle tendency
 *    - Using each polygon side bigger than @param trackWidth and it's angle in a descendant order by
 *    polygon side
 *
 * @param cartesianPolygonRepresentation Polygon representation to do that
 * @param trackWidth Track width to be explored
 */
export const getMissionPlannerCandidatesParameters = (
  cartesianPolygonRepresentation: CartesianPolygonRepresentation,
  trackWidth: number
): {
  routeAngle: number;
  startPoint: CartesianPoint;
}[] => {
  let generalRouteStartPoint: {
    point: CartesianPoint;
    side: number;
  } | null = null;

  const homePointInfo = cartesianPolygonRepresentation.homePoint;
  if (homePointInfo !== null) {
    generalRouteStartPoint = {
      point: homePointInfo.polygonNearbyPoint,
      side: homePointInfo.polygonNearbySide,
    };
  }

  const cartesianPolygon = cartesianPolygonRepresentation.cartesianPolygon;

  return [
    ...getPolygonAngleTendencyMissionPlannerCandidates(cartesianPolygon, generalRouteStartPoint),
    ...getPolygonSidesMissionPlannerCandidates(
      cartesianPolygon,
      trackWidth,
      generalRouteStartPoint
    ),
  ];
};

/**
 * Find mission planner mesh
 * Basically that function finds a mesh of consecutive lines that overlap a polygon inside that one.
 *
 * O(max(n^2, n * M)) procedure. M means the amount of mesh lines
 *
 * It will use the base point and the route angle to create a mesh line and finds every polygon intersection
 * point and after that will create another line with track width distance and finds the mesh intersection points
 * if they exists.
 * That are some mathematical examples (use the image comments extension to see):
 * (COMMENTS NOT LIKE DOCUMENTATION, OPEN THE THAT FILE TO SEE)
 *
 * As we can see, that new implementation doesn't have the previous mission planner limitation on a concave
 * polygon, but create a new situation to connect that mesh route and create a mission planner route, the
 * choice between fly outside the polygon one or multiple times. The Example 3 exposes that.
 */
/// Example 1: [package/documentation/images/mission_planner_mesh_example1.png]
/// Example 2: [package/documentation/images/mission_planner_mesh_example2.png]
/// Example 3: [package/documentation/images/mission_planner_mesh_example3.png]
export const findMissionPlannerMesh = (
  cartesianPolygonRepresentation: CartesianPolygonRepresentation,
  routeAngle: number,
  basePoint: CartesianPoint,
  trackWidth: number
): MeshLine[] => {
  ///assert(routeAngle >= -90 && routeAngle <= 90);

  const result: _MeshLine[] = [];

  let initialMeshLine: CartesianLine;
  let iterMeshLine: CartesianLine;
  if (routeAngle === 90 || routeAngle === -90) {
    iterMeshLine = {
      equationA: 1,
      equationB: 0,
      equationC: -basePoint.x,
    };
  } else {
    /// y = ax + b

    /// first we need the route angle as the line angle
    const radiansAngle = angleToRadians(routeAngle);
    const angularCoefficient = Math.tan(radiansAngle);

    /// y = ax + b <=> b = y - ax
    const independentTerm = basePoint.y - angularCoefficient * basePoint.x;

    /// Now we have y = angularCoefficient * x + independentTerm
    /// But we need ax + by + c = 0, so:
    /// y = angularCoefficient * x + independentTerm <=>
    /// - angularCoefficient * x + y - independentTerm = 0

    iterMeshLine = {
      equationA: -angularCoefficient,
      equationB: 1,
      equationC: -independentTerm,
    };
  }

  initialMeshLine = iterMeshLine;

  let meshLineIndex = 0;
  let failureOnFirstMeshLine = false;
  let exploringInOppositeDirection = false;

  /// O(n * M), M means the amount of mesh lines that we could put in that polygon using the route angle
  /// In a general case M is sqrt(A)/trackWidth and A is the polygon area
  while (true) {
    let iterMeshLineIntersections: {
      point: CartesianPoint;
      intersectionSideIndex: number;
    }[] = [];

    const cartesianPolygon = cartesianPolygonRepresentation.cartesianPolygon;

    for (let i = 0; i < cartesianPolygon.length; i++) {
      const cartesianPointAndLine = cartesianPolygon[i];
      const nextCartesianPointAndLine = cartesianPolygon[(i + 1) % cartesianPolygon.length];

      const intersectionPoint = findIntersectionBetweenLineAndLineSegment(iterMeshLine, {
        p1: cartesianPointAndLine.point,
        p2: nextCartesianPointAndLine.point,
      });

      if (intersectionPoint !== undefined) {
        if (
          iterMeshLineIntersections.find(
            (e) => e.point.x === intersectionPoint.x && e.point.y === intersectionPoint.y
          ) === undefined
        ) {
          iterMeshLineIntersections.push({
            point: intersectionPoint,
            intersectionSideIndex: i,
          });
        }
      }
    }

    if (iterMeshLineIntersections.length % 2 === 1) {
      iterMeshLineIntersections.pop();
    }

    // TODO: Check that condition if there isn't any line yet, so it's the process to find the first one line.
    // Can the base point be in a distance like that?
    // If the base point can be in that distance is there a chance that we create a infinite loop to find that
    // first line? We could iterate checking if the distance is decreasing
    if (iterMeshLineIntersections.length === 0) {
      if (result.length === 0 && !failureOnFirstMeshLine) {
        failureOnFirstMeshLine = true;
      } else {
        if (!exploringInOppositeDirection) {
          exploringInOppositeDirection = true;
          iterMeshLine = initialMeshLine;

          meshLineIndex = -1;
        } else {
          break;
        }
      }
    } else {
      if (result.length === 0) {
        initialMeshLine = iterMeshLine;
      }
    }

    iterMeshLineIntersections.sort((a, b) => (a.point.x < b.point.x ? -1 : 1));

    const polygon = cartesianPolygonRepresentation.cartesianPolygon.map((e) => e.point);

    for (let i = 0; i < iterMeshLineIntersections.length - 1; i = i + 2) {
      const start = iterMeshLineIntersections[i].point;
      const end = iterMeshLineIntersections[i + 1].point;

      const middlePoint: CartesianPoint = {
        x: (start.x + end.x) / 2,
        y: (start.y + end.y) / 2,
      };
      if (!checkPointInsidePolygon(middlePoint, polygon)) {
        continue;
      }

      result.push(
        new _MeshLine(
          start,
          iterMeshLineIntersections[i].intersectionSideIndex,
          end,
          iterMeshLineIntersections[i + 1].intersectionSideIndex,
          meshLineIndex,
          createCartesianPointsLineEquation(start, end),
          0,
          0,
          distanceBetweenPoints(start, end)
        )
      );
    }

    if (iterMeshLineIntersections.length > 0) {
      if (exploringInOppositeDirection) {
        meshLineIndex--;
      } else {
        meshLineIndex++;
      }
    }

    /// Reset iter mesh line intersections to iterate with a new mesh line
    iterMeshLineIntersections = [];

    const equationCAddTerm =
      trackWidth *
      Math.sqrt(Math.pow(iterMeshLine.equationA, 2) + Math.pow(iterMeshLine.equationB, 2));

    let addTermConsideringDirectionToExplore = equationCAddTerm;
    if (exploringInOppositeDirection) {
      addTermConsideringDirectionToExplore = -equationCAddTerm;
    }

    /// Now we need to walk track width to iterate in the next mesh line
    iterMeshLine = {
      equationA: iterMeshLine.equationA,
      equationB: iterMeshLine.equationB,
      equationC: iterMeshLine.equationC + addTermConsideringDirectionToExplore,
    };
  }

  if (result.length === 0) return [];

  const hasMeshLineIndexGreaterOrEqualTo1 = result
    .map((e) => e.meshLineIndex >= 1)
    .reduce((a, b) => a || b);

  return result.map((e) => ({
    ...e.toType(),
    meshLineIndex: !hasMeshLineIndexGreaterOrEqualTo1 ? -1 * e.meshLineIndex : e.meshLineIndex,
  }));
};

/**
 * Find mission planner routes. That method will create a route connecting the mesh route
 *
 * O(n) procedure
 * @param missionPlannerMesh Mesh route
 * @param originalPolygonPoints Original polygon points. Necessary to check if the home point is internal from the polygon.
 * @param paddingPolygonPoints Padding polygon points. The reference polygon to create the route.
 * @param cartesianPolygonHomePoint Information about the home point. It could be the home point or the parameters to create one.
 * @param trackWidth Track Width
 * @param canUseOutsidePolygonSegments preference to remove routes outside polygon
 */
export const findMissionPlannerRoutes = (
  missionPlannerMesh: MeshLine[],
  originalPolygonPoints: CartesianPoint[],
  paddingPolygonPoints: CartesianPoint[],
  cartesianPolygonHomePoint: CartesianPolygonRepresentation["homePoint"],
  trackWidth: number,
  canUseOutsidePolygonSegments: boolean
): CartesianMissionPlannerRoute[] => {
  if (missionPlannerMesh.length === 0) return [];

  let positiveMeshLineIndexesSector: _MeshLine[] = [];
  let negativeMeshLineIndexesSector: _MeshLine[] = [];

  for (const meshLine of missionPlannerMesh) {
    if (meshLine.meshLineIndex < 0) {
      negativeMeshLineIndexesSector.push(_MeshLine.fromType(meshLine));
    } else {
      positiveMeshLineIndexesSector.push(_MeshLine.fromType(meshLine));
    }
  }

  let homePointNullable: {
    point: CartesianPoint;
    distanceToPolygon: number;
  } | null = null;

  if (cartesianPolygonHomePoint !== null) {
    const side = cartesianPolygonHomePoint.polygonNearbySide;

    if (cartesianPolygonHomePoint.point !== null) {
      homePointNullable = {
        point: cartesianPolygonHomePoint.point,
        distanceToPolygon:
          cartesianPolygonHomePoint.distanceToPolygon ??
          distanceBetweenPointAndSegmentAndNearbyPoint(
            cartesianPolygonHomePoint.point,
            originalPolygonPoints[side],
            originalPolygonPoints[(side + 1) % originalPolygonPoints.length]
          ).distance,
      };
    } else {
      const homePointPoint = createHomePointBasedOnNearestPolygonSide(
        cartesianPolygonHomePoint.polygonNearbyPoint,
        cartesianPolygonHomePoint.polygonNearbySide,
        originalPolygonPoints,
        defaultHomePointDistanceToPolygon
      );

      homePointNullable = {
        point: homePointPoint,
        distanceToPolygon: defaultHomePointDistanceToPolygon,
      };
    }
  }

  let homePoint: {
    point: CartesianPoint;
    distanceToPolygon: number;
  };

  if (homePointNullable !== null) {
    homePoint = homePointNullable;
  } else {
    const firstMeshLine = missionPlannerMesh[0];

    const routeStartNearbyPointAndSide = getPointNearbyPolygonPointDistanceAndSide(
      firstMeshLine.start,
      originalPolygonPoints
    );

    const homePointPoint = createHomePointBasedOnNearestPolygonSide(
      routeStartNearbyPointAndSide.nearbyPoint,
      routeStartNearbyPointAndSide.side,
      originalPolygonPoints,
      defaultHomePointDistanceToPolygon
    );

    homePoint = {
      point: homePointPoint,
      distanceToPolygon: defaultHomePointDistanceToPolygon,
    };
  }

  const createMeshLineSectorsToAvoidOutsidePolygonBasedOnProximity = (
    _meshLines: _MeshLine[]
  ): MeshLineSector[] => {
    if (_meshLines.length === 0) return [];

    let result: MeshLineSector[] = [];

    let meshLines = [..._meshLines.map((e, index) => ({ meshLine: e, index: index }))];

    while (meshLines.length > 0) {
      const actualMeshLineIndex = meshLines[0].meshLine.meshLineIndex;
      const meshLinesWithSameIndex = meshLines.filter(
        (e) => e.meshLine.meshLineIndex === actualMeshLineIndex
      );
      const usedIndexes = meshLinesWithSameIndex.map((e) => e.index);

      const availableSectorsIndexesToAdd: number[] = result.map((_, index) => index);

      if (result.length === 0) {
        const meshLineToAddInSector = meshLinesWithSameIndex.splice(0, 1)[0].meshLine;
        result = [...result, new MeshLineSector([meshLineToAddInSector])];
      }

      while (meshLinesWithSameIndex.length > 0) {
        const meshLineToAddInSector = meshLinesWithSameIndex.splice(0, 1)[0].meshLine;

        const distanceForEachMeshLineSector = availableSectorsIndexesToAdd.map((sectorIndex) => ({
          distance: result[sectorIndex].meshLineDistanceToLastPoint(meshLineToAddInSector),
          index: sectorIndex,
        }));

        if (distanceForEachMeshLineSector.length === 0) {
          result = [...result, new MeshLineSector([meshLineToAddInSector])];
        } else {
          const sectorToAdd = distanceForEachMeshLineSector.reduce((a, b) =>
            a.distance < b.distance ? a : b
          );

          result[sectorToAdd.index] = new MeshLineSector([
            ...result[sectorToAdd.index].meshLines,
            meshLineToAddInSector,
          ]);

          availableSectorsIndexesToAdd.splice(sectorToAdd.index, 1);
        }
      }

      meshLines = meshLines
        .filter((e) => !usedIndexes.includes(e.index))
        .map((e, index) => ({ meshLine: e.meshLine, index: index }));
    }

    return result;
  };

  const firstSideMeshLinesSectorsToAvoidOutsideRoute =
    createMeshLineSectorsToAvoidOutsidePolygonBasedOnProximity(positiveMeshLineIndexesSector);
  const secondSideMeshLinesSectorsToAvoidOutsideRoute =
    createMeshLineSectorsToAvoidOutsidePolygonBasedOnProximity(negativeMeshLineIndexesSector);

  let createdTrackWidthPaddingPolygons: {
    [trackWidthAmount: number]: CartesianPoint[] | null;
  } = {};
  const createTrackWidthPaddingPolygon = (paddingAmount: number): CartesianPoint[] | null => {
    if (createdTrackWidthPaddingPolygons[paddingAmount] === undefined) {
      let cartesianPolygon: CartesianPoint[] | null;

      const paddingPolygonRepresentation = _applyPaddingInPolygonPoints(
        paddingPolygonPoints,
        paddingAmount * trackWidth,
        false
      );
      if (!checkCartesianPolygonExists(paddingPolygonRepresentation)) {
        cartesianPolygon = null;
      } else {
        cartesianPolygon = paddingPolygonRepresentation;
      }

      createdTrackWidthPaddingPolygons = {
        ...createdTrackWidthPaddingPolygons,
        [paddingAmount]: cartesianPolygon,
      };
    }

    return createdTrackWidthPaddingPolygons[paddingAmount];
  };

  let positiveNegativeMeshLineSectorsPossibilities: RoutePossibility[] = [new RoutePossibility([])];
  if (positiveMeshLineIndexesSector.length > 0) {
    positiveNegativeMeshLineSectorsPossibilities = positiveNegativeMeshLineSectorsPossibilities
      .map((e) =>
        e.addMeshLinesSector(
          paddingPolygonPoints,
          new MeshLineSector(positiveMeshLineIndexesSector),
          createTrackWidthPaddingPolygon
        )
      )
      .reduce((a, b) => [...a, ...b]);
  }
  if (negativeMeshLineIndexesSector.length > 0) {
    positiveNegativeMeshLineSectorsPossibilities = positiveNegativeMeshLineSectorsPossibilities
      .map((e) =>
        e.addMeshLinesSector(
          paddingPolygonPoints,
          new MeshLineSector(negativeMeshLineIndexesSector),
          createTrackWidthPaddingPolygon
        )
      )
      .reduce((a, b) => [...a, ...b]);
  }

  let possibilities = [...positiveNegativeMeshLineSectorsPossibilities];

  if (
    firstSideMeshLinesSectorsToAvoidOutsideRoute.length > 1 ||
    secondSideMeshLinesSectorsToAvoidOutsideRoute.length > 1
  ) {
    let routePossibilityToAvoidOutsideRoute: RoutePossibility[] = [new RoutePossibility([])];
    let hasPossibilityAvoidingOutsideRegion = false;

    const sectorsToAdd = [
      ...firstSideMeshLinesSectorsToAvoidOutsideRoute,
      ...secondSideMeshLinesSectorsToAvoidOutsideRoute,
    ];

    for (let i = 0; i < sectorsToAdd.length; i++) {
      const meshLineSectorToAdd = sectorsToAdd[i];

      if (meshLineSectorToAdd.meshLines.length > 0) {
        hasPossibilityAvoidingOutsideRegion = true;
        routePossibilityToAvoidOutsideRoute = routePossibilityToAvoidOutsideRoute
          .map((e) =>
            e.addMeshLinesSector(
              paddingPolygonPoints,
              meshLineSectorToAdd,
              createTrackWidthPaddingPolygon
            )
          )
          .reduce((a, b) => [...a, ...b]);
      }
    }

    if (hasPossibilityAvoidingOutsideRegion) {
      possibilities = [...routePossibilityToAvoidOutsideRoute, ...possibilities];
    }
  }

  const getPossibilityRoute = (possibility: RoutePossibility): CartesianMissionPlannerRoute => {
    return {
      homePoint: homePoint,
      route: possibility.getRoute(originalPolygonPoints),
    };
  };

  let routes = possibilities.map(getPossibilityRoute);
  if (!canUseOutsidePolygonSegments) {
    routes = routes.filter((route) => {
      const hasOutsideSegment = route.route.find((e) => e.outsideSegment) !== undefined;

      return !hasOutsideSegment;
    });
  }

  return routes;
};

const findIntersectionBetweenSegments = (a: Segment, b: Segment): CartesianPoint | undefined => {
  const x1 = a.p1.x;
  const y1 = a.p1.y;
  const x2 = a.p2.x;
  const y2 = a.p2.y;
  const x3 = b.p1.x;
  const y3 = b.p1.y;
  const x4 = b.p2.x;
  const y4 = b.p2.y;

  const alphaBetaDenominator = (x4 - x3) * (y2 - y1) - (y4 - y3) * (x2 - x1);
  if (alphaBetaDenominator === 0) return undefined;

  const alpha = ((x4 - x3) * (y3 - y1) - (y4 - y3) * (x3 - x1)) / alphaBetaDenominator;
  const beta = ((x2 - x1) * (y3 - y1) - (y2 - y1) * (x3 - x1)) / alphaBetaDenominator;

  if (alpha < 0 || alpha > 1 || beta < 0 || beta > 1) return undefined;

  return {
    x: x1 + alpha * (x2 - x1),
    y: y1 + alpha * (y2 - y1),
  };
};

const createHomePointBasedOnNearestPolygonSide = (
  nearestPolygonPoint: CartesianPoint,
  nearestPolygonSide: number,
  polygon: CartesianPoint[],
  distance: number
): CartesianPoint => {
  const initialSidePoint = polygon[nearestPolygonSide];
  const finalSidePoint = polygon[(nearestPolygonSide + 1) % polygon.length];
  const sideVector: Vector = {
    deltaX: finalSidePoint.x - initialSidePoint.x,
    deltaY: finalSidePoint.y - initialSidePoint.y,
  };

  const directionVector = rotate90PositiveDegreesOnVector(sideVector);
  const directionVectorNorm = vectorNorm(directionVector);

  const paddingVector: Vector = {
    deltaX: (directionVector.deltaX / directionVectorNorm) * distance,
    deltaY: (directionVector.deltaY / directionVectorNorm) * distance,
  };

  let homePoint: CartesianPoint = {
    x: nearestPolygonPoint.x + paddingVector.deltaX,
    y: nearestPolygonPoint.y + paddingVector.deltaY,
  };

  if (checkPointInsidePolygon(homePoint, polygon)) {
    homePoint = {
      x: nearestPolygonPoint.x - paddingVector.deltaX,
      y: nearestPolygonPoint.y - paddingVector.deltaY,
    };
  }

  return homePoint;
};

const createHomePointBasedOnFirstMeshLine = (
  firstMeshLine: MeshLine,
  basePoint: CartesianPoint,
  addDistance: number,
  polygon: CartesianPoint[]
): CartesianPoint => {
  const firstMeshLineSegmentVector: Vector = {
    deltaX: firstMeshLine.end.x - firstMeshLine.start.x,
    deltaY: firstMeshLine.end.y - firstMeshLine.start.y,
  };

  const firstMeshLineSegmentVectorNorm = vectorNorm(firstMeshLineSegmentVector);

  const multiplicationFactor = (-1 * addDistance) / firstMeshLineSegmentVectorNorm;

  const getHomePointPlaceVector: Vector = {
    deltaX: firstMeshLineSegmentVector.deltaX * multiplicationFactor,
    deltaY: firstMeshLineSegmentVector.deltaY * multiplicationFactor,
  };

  let homePointPoint: CartesianPoint = {
    x: basePoint.x + getHomePointPlaceVector.deltaX,
    y: basePoint.y + getHomePointPlaceVector.deltaY,
  };

  if (checkPointInsidePolygon(homePointPoint, polygon)) {
    homePointPoint = {
      x: basePoint.x - getHomePointPlaceVector.deltaX,
      y: basePoint.y - getHomePointPlaceVector.deltaY,
    };
  }

  return homePointPoint;
};

/**
 * Get mesh lines score
 *
 * O(n) procedure
 *
 * A higher score is worse.
 *
 * That method will create an score using the mesh lines, that score will be like the final route score,
 * so will have a linear distance and amount of waypoints approach
 * polygon area
 * @param meshLines
 * @param polygonArea
 * @param trackWidth
 */
export const getMeshLinesScore = (
  meshLines: MeshLine[],
  polygonArea: number,
  trackWidth: number
): number => {
  if (meshLines.length === 0) return -1;

  const minimalDistance = polygonArea / trackWidth;

  const linearDistanceApproach =
    Math.abs(meshLines.map((e) => e.length).reduce((a, b) => a + b) - minimalDistance) /
    minimalDistance;

  /// We need an expected amount of waypoints to compare with the result waypoints
  /// to do that we can imagine a square who has the same area as that polygon
  const squareSide = Math.sqrt(polygonArea);
  /// We can imagine that we have an amount of track width in that side
  const squareSideAmountOfTrackWidth = squareSide / trackWidth;
  /// An expected amount of waypoints in that polygon would be
  const squareExpectedAmountOfWaypoints = 2 * squareSideAmountOfTrackWidth;

  const amountOfWaypointsApproach =
    Math.abs(meshLines.length * 2 - squareExpectedAmountOfWaypoints) /
    squareExpectedAmountOfWaypoints;

  return linearDistanceApproach + amountOfWaypointsApproach;
};

/**
 * Get mission route linear distance score
 *
 * O(n) procedure
 *
 * A higher score is worse.
 *
 * That method will evaluate the percentage distance from the value of the route distance to the minimal
 * expected distance which is every polygon covered by track width and the origin and destination using
 * the near point from the home point
 * @param homePoint Home point
 * @param homePointDistanceToPolygonBorder Home point distance to the polygon
 * @param route Route to evaluate
 * @param trackWidth Track width
 * @param polygonArea Polygon area
 */
export const getMissionRouteLinearDistanceScore = (
  homePoint: CartesianPoint,
  homePointDistanceToPolygonBorder: number,
  route: CartesianPlannerRoutePoint[],
  trackWidth: number,
  polygonArea: number
): number => {
  const routePoints: CartesianPoint[] = [homePoint, ...route, homePoint];

  const distance = routePoints
    .map((point, index) => {
      if (index === routePoints.length - 1) return 0;

      const nextPoint = routePoints[index + 1];

      return distanceBetweenPoints(point, nextPoint);
    })
    .reduce((a, b) => a + b);

  const minimalDistance = polygonArea / trackWidth + homePointDistanceToPolygonBorder * 2;

  return Math.abs(distance - minimalDistance) / minimalDistance;
};

/**
 * Get mission route amount of waypoints score
 *
 * O(1) procedure
 *
 * A higher score is worse.
 *
 * @param route Route to evaluate
 */
export const getMissionRouteAmountOfWaypointsScore = (
  polygonArea: number,
  trackWidth: number,
  route: CartesianPlannerRoutePoint[]
): number => {
  /// We need an expected amount of waypoints to compare with the result waypoints
  /// to do that we can imagine a square who has the same area as that polygon
  const squareSide = Math.sqrt(polygonArea);
  /// We can imagine that we have an amount of track width in that side
  const squareSideAmountOfTrackWidth = squareSide / trackWidth;
  /// An expected amount of waypoints in that polygon would be
  const squareExpectedAmountOfWaypoints = 2 * squareSideAmountOfTrackWidth;

  return Math.abs(route.length - squareExpectedAmountOfWaypoints) / squareExpectedAmountOfWaypoints;
};

/**
 * Get mission route general score.
 *
 * O(1) procedure
 *
 * That method will combine each score and create a result score to the route
 *
 * @param routeLinearDistanceScore Value obtained by getMissionRouteLinearDistanceScore method
 * @param routeAmountOfWaypointsScore Value obtained by getMissionRouteAmountOfWaypointsScore method
 */
export const getMissionRouteGeneralScore = (
  routeLinearDistanceScore: number,
  routeAmountOfWaypointsScore: number
): number => {
  return (routeLinearDistanceScore + routeAmountOfWaypointsScore) * 100;
};

/**
 * Convert cartesian route to a geographic route
 */
export const convertCartesianRouteToGeographicRoute = (
  cartesianRoute: CartesianMissionPlannerRoute,
  baseCartesianCoordinatesPoint: Location,
  routeBasePoint: CartesianPoint,
  routeAngle: number
): {
  plan: MissionPlannerRoute;
  basePoint: Location;
  routeAngle: number;
} => {
  const pointsToConvert: CartesianPoint[] = [
    ...cartesianRoute.route,
    cartesianRoute.homePoint.point,
    routeBasePoint,
  ];
  const convertedPoints = convertCartesianPointsToGeographicLocations(
    baseCartesianCoordinatesPoint,
    pointsToConvert
  );
  const routeBasePointLocation = convertedPoints.pop()!;
  const homePointLocation = convertedPoints.pop()!;

  return {
    basePoint: routeBasePointLocation,
    routeAngle: routeAngle,
    plan: {
      homePoint: homePointLocation,
      route: cartesianRoute.route.map(
        (e, index): PlannerRoutePoint => ({
          ...e,
          ...convertedPoints[index],
        })
      ),
    },
  };
};

/**
 * Using a geographic polygon find the best plan area
 *
 * In a  general analysis this is an O (n^3) algorithm
 */
export const planArea = (
  polygon: Location[],
  padding: number,
  trackWidth: number,
  basePointToCreateHomePoint: Location | null,
  canUseOutsidePolygonSegments: boolean
): {
  plan: MissionPlannerRoute;
  basePoint: Location;
  routeAngle: number;
} => {
  const cartesianPolygonRepresentation = createCartesianPolygonRepresentation(
    polygon,
    null,
    basePointToCreateHomePoint
  );
  const cartesianPaddingPolygonRepresentation = applyPaddingInCartesianPolygon(
    cartesianPolygonRepresentation,
    padding
  );
  const missionPlannerCandidatesParameters = getMissionPlannerCandidatesParameters(
    cartesianPaddingPolygonRepresentation,
    trackWidth
  );
  const meshLinesCandidates = missionPlannerCandidatesParameters.map((parameters) => {
    const meshLines = findMissionPlannerMesh(
      cartesianPaddingPolygonRepresentation,
      parameters.routeAngle,
      parameters.startPoint,
      trackWidth
    );
    const score = getMeshLinesScore(
      meshLines,
      cartesianPaddingPolygonRepresentation.polygonArea,
      trackWidth
    );

    return {
      meshLines: meshLines,
      score: score,
      parameters: parameters,
    };
  });

  const possibleMeshLines = meshLinesCandidates.filter((e) => e.score !== -1);
  ///ascendant order
  possibleMeshLines.sort((a, b) => (a.score > b.score ? 1 : -1));

  for (const bestMeshLines of possibleMeshLines) {
    const missionRoutes = findMissionPlannerRoutes(
      bestMeshLines.meshLines,
      cartesianPolygonRepresentation.cartesianPolygon.map((e) => e.point),
      cartesianPaddingPolygonRepresentation.cartesianPolygon.map((e) => e.point),
      cartesianPolygonRepresentation.homePoint,
      trackWidth,
      canUseOutsidePolygonSegments
    );

    const missionRoutesAndScores = missionRoutes.map((route) => {
      const linearDistanceScore = getMissionRouteLinearDistanceScore(
        route.homePoint.point,
        route.homePoint.distanceToPolygon,
        route.route,
        trackWidth,
        cartesianPaddingPolygonRepresentation.polygonArea
      );
      const amountOfWaypointsScore = getMissionRouteAmountOfWaypointsScore(
        cartesianPaddingPolygonRepresentation.polygonArea,
        trackWidth,
        route.route
      );
      const score = getMissionRouteGeneralScore(linearDistanceScore, amountOfWaypointsScore);

      return {
        route: route,
        score: score,
      };
    });

    if (missionRoutesAndScores.length === 0) continue;

    const cartesianRoute = missionRoutesAndScores.reduce((a, b) =>
      a.score < b.score ? a : b
    ).route;

    return convertCartesianRouteToGeographicRoute(
      cartesianRoute,
      cartesianPaddingPolygonRepresentation.basePoint,
      bestMeshLines.parameters.startPoint,
      bestMeshLines.parameters.routeAngle
    );
  }

  throw Error("Couldn't find a route");
};

const getPolygonAngleTendencyMissionPlannerCandidates = (
  cartesianPolygon: CartesianPolygonVertex[],
  generalRouteStartPoint: {
    point: CartesianPoint;
    side: number;
  } | null
): MissionPlannerCandidateParameters[] => {
  let xMinInterval = cartesianPolygon[0].point.x;
  let xMaxInterval = cartesianPolygon[0].point.x;
  let yMinInterval = cartesianPolygon[0].point.y;
  let yMaxInterval = cartesianPolygon[0].point.y;
  let xSum = 0;
  let ySum = 0;

  let angleMultipliedBySideLengthSum = 0;
  let perimeter = 0;

  for (const vertex of cartesianPolygon) {
    const x = vertex.point.x;
    const y = vertex.point.y;

    if (x < xMinInterval) {
      xMinInterval = x;
    }
    if (x > xMaxInterval) {
      xMaxInterval = x;
    }
    if (y < yMinInterval) {
      yMinInterval = y;
    }
    if (y > yMaxInterval) {
      yMaxInterval = y;
    }

    xSum = xSum + x;
    ySum = ySum + y;

    angleMultipliedBySideLengthSum = angleMultipliedBySideLengthSum + vertex.angle * vertex.length;
    perimeter = perimeter + vertex.length;
  }

  let angleTendency = angleMultipliedBySideLengthSum / perimeter;
  if (angleTendency > 360) {
    angleTendency = angleTendency % 360;
  }
  if (angleTendency > 180) {
    angleTendency = angleTendency - 360;
  }
  if (angleTendency < -180) {
    angleTendency = angleTendency + 360;
  }

  if (generalRouteStartPoint !== null) {
    return [
      {
        routeAngle: angleTendency,
        startPoint: generalRouteStartPoint.point,
      },
    ];
  }

  const closerLineAngleToAngleTendencyStartPoint = cartesianPolygon
    .map((e) => ({
      point: e.point,
      angleDiff: Math.abs(e.angle - angleTendency),
    }))
    .reduce((a, b) => (a.angleDiff < b.angleDiff ? a : b)).point;

  let boundingBoxStartPointAccordingAngleTendency: CartesianPoint;
  if (angleTendency >= 45 && angleTendency <= 135) {
    boundingBoxStartPointAccordingAngleTendency = {
      x: xSum / cartesianPolygon.length,
      y: yMaxInterval,
    };
  } else if (
    (angleTendency >= 135 && angleTendency <= 180) ||
    (angleTendency <= -180 && angleTendency >= -135)
  ) {
    boundingBoxStartPointAccordingAngleTendency = {
      x: xMinInterval,
      y: ySum / cartesianPolygon.length,
    };
  } else if (angleTendency >= -135 && angleTendency <= -45) {
    boundingBoxStartPointAccordingAngleTendency = {
      x: xSum / cartesianPolygon.length,
      y: yMinInterval,
    };
  } else {
    boundingBoxStartPointAccordingAngleTendency = {
      x: xMaxInterval,
      y: ySum / cartesianPolygon.length,
    };
  }

  return [
    {
      routeAngle: angleTendency,
      startPoint: closerLineAngleToAngleTendencyStartPoint,
    },
    {
      routeAngle: angleTendency,
      startPoint: boundingBoxStartPointAccordingAngleTendency,
    },
  ];
};

const getPolygonSidesMissionPlannerCandidates = (
  cartesianPolygon: CartesianPolygonVertex[],
  trackWidth: number,
  generalRouteStartPoint: {
    point: CartesianPoint;
    side: number;
  } | null
): MissionPlannerCandidateParameters[] => {
  if (generalRouteStartPoint !== null) {
    const polygon = cartesianPolygon.map((e) => e.point);

    let nearbyPaddingPolygonVertex: CartesianPoint | null = null;

    const nearbyPointAndSide = getPointNearbyPolygonPointDistanceAndSide(
      generalRouteStartPoint.point,
      polygon
    );

    if (nearbyPointAndSide.distance > 0) {
      if (
        distanceBetweenPoints(generalRouteStartPoint.point, polygon[nearbyPointAndSide.side]) <
        distanceBetweenPoints(
          generalRouteStartPoint.point,
          polygon[(nearbyPointAndSide.side + 1) % polygon.length]
        )
      ) {
        nearbyPaddingPolygonVertex = polygon[nearbyPointAndSide.side];
      } else {
        nearbyPaddingPolygonVertex = polygon[(nearbyPointAndSide.side + 1) % polygon.length];
      }
    }

    const result: MissionPlannerCandidateParameters[] = [];

    if (nearbyPaddingPolygonVertex !== null) {
      result.push({
        startPoint: generalRouteStartPoint.point,
        routeAngle: angleBetweenPoints(generalRouteStartPoint.point, nearbyPaddingPolygonVertex),
      });
    }
    result.push({
      startPoint: generalRouteStartPoint.point,
      routeAngle: (cartesianPolygon[generalRouteStartPoint.side].angle + 90) % 180,
    });
    result.push({
      startPoint: generalRouteStartPoint.point,
      routeAngle: cartesianPolygon[generalRouteStartPoint.side].angle,
    });

    return result;
  }

  let sides = [...cartesianPolygon.filter((e) => e.length >= trackWidth)];

  sides.sort((a, b) => (a.length > b.length ? 1 : -1));

  return sides.map((e) => ({
    routeAngle: e.angle,
    startPoint: e.point,
  }));
};

const angleBetweenPoints = (a: CartesianPoint, b: CartesianPoint): number => {
  if (a.x === b.x) {
    if (a.x > b.x) {
      return -90;
    }
    return 90;
  }

  const tan = (b.y - a.y) / (b.x - a.x);
  const atan = Math.atan(tan);

  return radiansAngleToAngle(atan);
};

type RoutePossibilitySectorInfo = {
  meshLineSector: MeshLineSector;
  connectionToNext: CartesianPoint[];
  arbitraryConnection: boolean;
};

class RoutePossibility {
  public meshLines: RoutePossibilitySectorInfo[];

  constructor(meshLines: RoutePossibilitySectorInfo[]) {
    this.meshLines = meshLines;
  }

  applyPadding(
    sides: {
      segment: Segment;
      index: number;
    }[],
    /// Necessary because we will not create again a padding polygon if it was already created
    createTrackWidthPaddingPolygon: (paddingAmount: number) => CartesianPoint[] | null
  ): RoutePossibility | null {
    let hasPaddingMeshLine = false;
    const meshLines: RoutePossibilitySectorInfo[] = [];

    for (const meshLineInfo of this.meshLines) {
      const paddingMeshLineSector = meshLineInfo.meshLineSector.applyPadding(
        sides,
        createTrackWidthPaddingPolygon
      );
      if (paddingMeshLineSector !== null) {
        hasPaddingMeshLine = true;
      }

      meshLines.push({
        meshLineSector: paddingMeshLineSector ?? meshLineInfo.meshLineSector,
        connectionToNext: meshLineInfo.connectionToNext,
        arbitraryConnection: meshLineInfo.arbitraryConnection,
      });
    }

    if (!hasPaddingMeshLine) return null;

    return new RoutePossibility(meshLines);
  }

  addMeshLinesSector(
    polygon: CartesianPoint[],
    meshLineSector: MeshLineSector, /// Necessary because we will not create again a padding polygon if it was already created
    createTrackWidthPaddingPolygon: (paddingAmount: number) => CartesianPoint[] | null
  ): RoutePossibility[] {
    if (meshLineSector.meshLines.length === 0) return [this];

    if (this.meshLines.length === 0) {
      this.meshLines.push({
        meshLineSector: meshLineSector,
        connectionToNext: [],
        arbitraryConnection: false,
      });

      return [this];
    }

    const result: RoutePossibility[] = [];

    const meshLines = [...this.meshLines];

    const lastMeshLinesInfo = meshLines.pop()!;

    result.push(
      new RoutePossibility([
        ...meshLines,
        {
          ...lastMeshLinesInfo,
          arbitraryConnection: true,
        },
        {
          meshLineSector: meshLineSector,
          arbitraryConnection: false,
          connectionToNext: [],
        },
      ])
    );

    let connectionPathUsingPolygonBorder = findPolygonNearPathToConnectTwoPointsOnPolygonBorders(
      polygon,
      lastMeshLinesInfo.meshLineSector.lastPoint,
      meshLineSector.firstPoint
    );
    if (connectionPathUsingPolygonBorder !== undefined) {
      const possibilityWithPadding = new RoutePossibility([
        ...meshLines,
        lastMeshLinesInfo,
        /// Here only to apply padding together
        {
          meshLineSector: meshLineSector,
          arbitraryConnection: false,
          connectionToNext: [],
        },
      ]).applyPadding(
        connectionPathUsingPolygonBorder.map((e) => ({
          segment: e.segment,
          index: e.polygonSideIndex,
        })),
        createTrackWidthPaddingPolygon
      );

      if (possibilityWithPadding !== null) {
        const possibilityWithPaddingMeshLines = [...possibilityWithPadding.meshLines];
        const newMeshLineSector = possibilityWithPaddingMeshLines.pop()!;
        const possibilityWithPaddingLastMeshLine = possibilityWithPaddingMeshLines.pop()!;

        const points = [
          ...connectionPathUsingPolygonBorder.map((e) => e.segment.p1),
          connectionPathUsingPolygonBorder[connectionPathUsingPolygonBorder.length - 1].segment.p2,
        ];

        result.push(
          new RoutePossibility([
            ...possibilityWithPaddingMeshLines,
            {
              meshLineSector: possibilityWithPaddingLastMeshLine.meshLineSector,
              connectionToNext: points,
              arbitraryConnection: false,
            },
            {
              meshLineSector: newMeshLineSector.meshLineSector,
              connectionToNext: [],
              arbitraryConnection: false,
            },
          ])
        );
      }
    }

    const meshLineSectorInOppositeDirection = meshLineSector.inOppositeDirection();

    result.push(
      new RoutePossibility([
        ...meshLines,
        {
          ...lastMeshLinesInfo,
          arbitraryConnection: true,
        },
        {
          meshLineSector: meshLineSectorInOppositeDirection,
          arbitraryConnection: false,
          connectionToNext: [],
        },
      ])
    );

    connectionPathUsingPolygonBorder = findPolygonNearPathToConnectTwoPointsOnPolygonBorders(
      polygon,
      lastMeshLinesInfo.meshLineSector.lastPoint,
      meshLineSectorInOppositeDirection.firstPoint
    );
    if (connectionPathUsingPolygonBorder !== undefined) {
      const possibilityWithPadding = new RoutePossibility([
        ...meshLines,
        lastMeshLinesInfo,
        /// Here only to apply padding together
        {
          meshLineSector: meshLineSectorInOppositeDirection,
          arbitraryConnection: false,
          connectionToNext: [],
        },
      ]).applyPadding(
        connectionPathUsingPolygonBorder.map((e) => ({
          segment: e.segment,
          index: e.polygonSideIndex,
        })),
        createTrackWidthPaddingPolygon
      );

      if (possibilityWithPadding !== null) {
        const possibilityWithPaddingMeshLines = [...possibilityWithPadding.meshLines];
        const newMeshLineSector = possibilityWithPaddingMeshLines.pop()!;
        const possibilityWithPaddingLastMeshLine = possibilityWithPaddingMeshLines.pop()!;

        const points = [
          ...connectionPathUsingPolygonBorder.map((e) => e.segment.p1),
          connectionPathUsingPolygonBorder[connectionPathUsingPolygonBorder.length - 1].segment.p2,
        ];

        result.push(
          new RoutePossibility([
            ...possibilityWithPaddingMeshLines,
            {
              meshLineSector: possibilityWithPaddingLastMeshLine.meshLineSector,
              connectionToNext: points,
              arbitraryConnection: false,
            },
            {
              meshLineSector: newMeshLineSector.meshLineSector,
              connectionToNext: [],
              arbitraryConnection: false,
            },
          ])
        );
      }
    }

    const meshLineSectorInOppositeOrder = meshLineSector.inOppositeOrder();

    result.push(
      new RoutePossibility([
        ...meshLines,
        {
          ...lastMeshLinesInfo,
          arbitraryConnection: true,
        },
        {
          meshLineSector: meshLineSectorInOppositeOrder,
          arbitraryConnection: false,
          connectionToNext: [],
        },
      ])
    );

    connectionPathUsingPolygonBorder = findPolygonNearPathToConnectTwoPointsOnPolygonBorders(
      polygon,
      lastMeshLinesInfo.meshLineSector.lastPoint,
      meshLineSectorInOppositeOrder.firstPoint
    );
    if (connectionPathUsingPolygonBorder !== undefined) {
      const possibilityWithPadding = new RoutePossibility([
        ...meshLines,
        lastMeshLinesInfo,
        /// Here only to apply padding together
        {
          meshLineSector: meshLineSectorInOppositeOrder,
          arbitraryConnection: false,
          connectionToNext: [],
        },
      ]).applyPadding(
        connectionPathUsingPolygonBorder.map((e) => ({
          segment: e.segment,
          index: e.polygonSideIndex,
        })),
        createTrackWidthPaddingPolygon
      );

      if (possibilityWithPadding !== null) {
        const possibilityWithPaddingMeshLines = [...possibilityWithPadding.meshLines];
        const newMeshLineSector = possibilityWithPaddingMeshLines.pop()!;
        const possibilityWithPaddingLastMeshLine = possibilityWithPaddingMeshLines.pop()!;

        const points = [
          ...connectionPathUsingPolygonBorder.map((e) => e.segment.p1),
          connectionPathUsingPolygonBorder[connectionPathUsingPolygonBorder.length - 1].segment.p2,
        ];

        result.push(
          new RoutePossibility([
            ...possibilityWithPaddingMeshLines,
            {
              meshLineSector: possibilityWithPaddingLastMeshLine.meshLineSector,
              connectionToNext: points,
              arbitraryConnection: false,
            },
            {
              meshLineSector: newMeshLineSector.meshLineSector,
              connectionToNext: [],
              arbitraryConnection: false,
            },
          ])
        );
      }
    }

    const meshLineSectorInOppositeDirectionAndOppositeOrder =
      meshLineSectorInOppositeOrder.inOppositeDirection();

    result.push(
      new RoutePossibility([
        ...meshLines,
        {
          ...lastMeshLinesInfo,
          arbitraryConnection: true,
        },
        {
          meshLineSector: meshLineSectorInOppositeDirectionAndOppositeOrder,
          arbitraryConnection: false,
          connectionToNext: [],
        },
      ])
    );

    connectionPathUsingPolygonBorder = findPolygonNearPathToConnectTwoPointsOnPolygonBorders(
      polygon,
      lastMeshLinesInfo.meshLineSector.lastPoint,
      meshLineSectorInOppositeDirectionAndOppositeOrder.firstPoint
    );
    if (connectionPathUsingPolygonBorder !== undefined) {
      const possibilityWithPadding = new RoutePossibility([
        ...meshLines,
        lastMeshLinesInfo,
        /// Here only to apply padding together
        {
          meshLineSector: meshLineSectorInOppositeDirectionAndOppositeOrder,
          arbitraryConnection: false,
          connectionToNext: [],
        },
      ]).applyPadding(
        connectionPathUsingPolygonBorder.map((e) => ({
          segment: e.segment,
          index: e.polygonSideIndex,
        })),
        createTrackWidthPaddingPolygon
      );

      if (possibilityWithPadding !== null) {
        const possibilityWithPaddingMeshLines = [...possibilityWithPadding.meshLines];
        const newMeshLineSector = possibilityWithPaddingMeshLines.pop()!;
        const possibilityWithPaddingLastMeshLine = possibilityWithPaddingMeshLines.pop()!;

        const points = [
          ...connectionPathUsingPolygonBorder.map((e) => e.segment.p1),
          connectionPathUsingPolygonBorder[connectionPathUsingPolygonBorder.length - 1].segment.p2,
        ];

        result.push(
          new RoutePossibility([
            ...possibilityWithPaddingMeshLines,
            {
              meshLineSector: possibilityWithPaddingLastMeshLine.meshLineSector,
              connectionToNext: points,
              arbitraryConnection: false,
            },
            {
              meshLineSector: newMeshLineSector.meshLineSector,
              connectionToNext: [],
              arbitraryConnection: false,
            },
          ])
        );
      }
    }

    return result;
  }

  getRoute(originalPolygonPoints: CartesianPoint[]): CartesianPlannerRoutePoint[] {
    let points: CartesianPlannerRoutePoint[] = [];

    for (let i = 0; i < this.meshLines.length; i++) {
      const info = this.meshLines[i];
      points = [
        ...points,
        ...[
          ...info.meshLineSector.getRoute(info.arbitraryConnection),
          ...info.connectionToNext.map((e) => ({
            ...e,
            outsideSegment: false,
            polygonSectorsConnectionsToNotRelease: false,
            connectionUsingBorder: true,
          })),
        ].map((e) => ({ ...e, meshSectorIndex: i })),
      ];
    }

    points = points.filter((point) => checkPointInsidePolygon(point, originalPolygonPoints));

    const pointsAndDistances = points.map((point, index) => {
      if (index === points.length - 1) {
        return {
          ...point,
          distance: 0,
        };
      }

      return {
        ...point,
        distance: distanceBetweenPoints(point, points[index + 1]),
      };
    });

    const resultWithoutOutsideSegment: CartesianPlannerRoutePoint[] = [];

    let closeDistanceAccumulator = 0;
    let closeDistancePoints: CartesianPlannerRoutePoint[] = [];

    const getCloseDistancesPointsPoint = (): CartesianPlannerRoutePoint => {
      const closeDistancePoint: CartesianPlannerRoutePoint = {
        x: closeDistancePoints.map((e) => e.x).reduce((a, b) => a + b) / closeDistancePoints.length,
        y: closeDistancePoints.map((e) => e.y).reduce((a, b) => a + b) / closeDistancePoints.length,
        connectionUsingBorder: closeDistancePoints
          .map((e) => e.connectionUsingBorder)
          .reduce((a, b) => a || b),
        meshSectorIndex: closeDistancePoints
          .map((e) => e.meshSectorIndex)
          .reduce((a, b) => (a > b ? a : b)),
        outsideSegment: closeDistancePoints.map((e) => e.outsideSegment).reduce((a, b) => a || b),
        polygonSectorsConnectionsToNotRelease: closeDistancePoints
          .map((e) => e.polygonSectorsConnectionsToNotRelease)
          .reduce((a, b) => a || b),
      };

      return closeDistancePoint;
    };

    for (let i = 0; i < pointsAndDistances.length; i++) {
      const point = pointsAndDistances[i];

      closeDistanceAccumulator = closeDistanceAccumulator + point.distance;
      closeDistancePoints.push(point);

      if (closeDistanceAccumulator > 1) {
        resultWithoutOutsideSegment.push(getCloseDistancesPointsPoint());

        closeDistanceAccumulator = 0;
        closeDistancePoints = [];
      }
    }
    if (closeDistancePoints.length > 0) {
      resultWithoutOutsideSegment.push(getCloseDistancesPointsPoint());
    }

    const resultWithOutsideSegmentsFlag = resultWithoutOutsideSegment.map((point, index) => {
      let outsideSegment = false;

      if (index === resultWithoutOutsideSegment.length - 1) {
        return {
          ...point,
          outsideSegment: outsideSegment,
        };
      }

      const nextPoint = resultWithoutOutsideSegment[index + 1];
      const actualSegment: Segment = {
        p1: point,
        p2: nextPoint,
      };
      const interceptionSide = originalPolygonPoints.find((polygonPoint, polygonPointIndex) => {
        const nextPolygonSide =
          originalPolygonPoints[(polygonPointIndex + 1) % originalPolygonPoints.length];

        const polygonSide: Segment = {
          p1: polygonPoint,
          p2: nextPolygonSide,
        };

        const interception = findIntersectionBetweenSegments(actualSegment, polygonSide);

        return interception !== undefined;
      });

      outsideSegment = interceptionSide !== undefined;

      return {
        ...point,
        outsideSegment: outsideSegment,
      };
    });

    return resultWithOutsideSegmentsFlag;
  }
}

const findPolygonNearPathToConnectTwoPointsOnPolygonBorders = (
  cartesianPolygon: CartesianPoint[],
  p1: CartesianPoint,
  p2: CartesianPoint
):
  | {
      segment: Segment;
      polygonSideIndex: number;
    }[]
  | undefined => {
  const checkP1InPolygonLines = cartesianPolygon.map((cartesianPolygonVertex, index) => {
    const nextPolygonVertex = cartesianPolygon[(index + 1) % cartesianPolygon.length];

    return checkPointInSegment(p1, {
      p1: cartesianPolygonVertex,
      p2: nextPolygonVertex,
    });
  });

  const checkP2InPolygonLines = cartesianPolygon.map((cartesianPolygonVertex, index) => {
    const nextPolygonVertex = cartesianPolygon[(index + 1) % cartesianPolygon.length];

    return checkPointInSegment(p2, {
      p1: cartesianPolygonVertex,
      p2: nextPolygonVertex,
    });
  });

  const p1PolygonLineIndex = checkP1InPolygonLines.findIndex((e) => e);
  const p2PolygonLineIndex = checkP2InPolygonLines.findIndex((e) => e);

  if (p1PolygonLineIndex < 0 || p2PolygonLineIndex < 0) return undefined;

  const getConnectionPathUsingIndexesBeginningInP1 = (
    connectionIndexes: number[],
    mode: "ascending" | "descending"
  ): {
    sides: {
      segment: Segment;
      polygonSideIndex: number;
    }[];
    distanceMeasure: number;
  } => {
    const sides: {
      segment: Segment;
      polygonSideIndex: number;
    }[] = [];

    let previousPoint = p1;

    //const segment: CartesianPoint[] = [p1];
    let totalDistanceMeasure = 0;

    for (let i = 0; i < connectionIndexes.length; i++) {
      const connectionIndex = connectionIndexes[i];

      let newPoint: CartesianPoint;

      if (i === connectionIndexes.length - 1) {
        newPoint = p2;
      } else {
        if (mode === "ascending") {
          let vertexIndex: number;

          vertexIndex = connectionIndex + 1;
          if (vertexIndex === cartesianPolygon.length) {
            vertexIndex = 0;
          }

          newPoint = cartesianPolygon[vertexIndex];
        } else {
          newPoint = cartesianPolygon[connectionIndex];
        }
      }

      sides.push({
        segment: {
          p1: previousPoint,
          p2: newPoint,
        },
        polygonSideIndex: connectionIndex,
      });

      const distanceMeasure =
        Math.pow(newPoint.x - previousPoint.x, 2) + Math.pow(newPoint.y - previousPoint.y, 2);
      totalDistanceMeasure = totalDistanceMeasure + distanceMeasure;

      previousPoint = newPoint;
    }

    return {
      sides: sides,
      distanceMeasure: totalDistanceMeasure,
    };
  };

  const indexesToConnectPointsInAscendingOrder: number[] = [];
  let i = p1PolygonLineIndex;
  while (i != p2PolygonLineIndex) {
    indexesToConnectPointsInAscendingOrder.push(i);

    i++;
    if (i === cartesianPolygon.length) {
      i = 0;
    }
  }
  indexesToConnectPointsInAscendingOrder.push(p2PolygonLineIndex);

  const indexesToConnectPointsInDescendingOrder: number[] = [];
  i = p1PolygonLineIndex;
  while (i != p2PolygonLineIndex) {
    indexesToConnectPointsInDescendingOrder.push(i);

    i--;
    if (i === -1) {
      i = cartesianPolygon.length - 1;
    }
  }
  indexesToConnectPointsInDescendingOrder.push(p2PolygonLineIndex);

  const pointsInAscendingIndexOrder = getConnectionPathUsingIndexesBeginningInP1(
    indexesToConnectPointsInAscendingOrder,
    "ascending"
  );
  const pointsInDescendingIndexOrder = getConnectionPathUsingIndexesBeginningInP1(
    indexesToConnectPointsInDescendingOrder,
    "descending"
  );

  if (pointsInAscendingIndexOrder.distanceMeasure < pointsInDescendingIndexOrder.distanceMeasure) {
    return pointsInAscendingIndexOrder.sides;
  } else {
    return pointsInDescendingIndexOrder.sides;
  }
};

class MeshLineSector {
  public meshLines: _MeshLine[];
  public firstPoint: CartesianPoint;
  public lastPoint: CartesianPoint;

  constructor(meshLines: _MeshLine[]) {
    //assert(meshLines.length > 0);

    this.meshLines = [];
    let newMeshLineIndex = 0;
    let lastMeshLineIndex = meshLines[0].meshLineIndex;
    for (const meshLine of meshLines) {
      const newMesh = meshLine.copyWith({});

      if (newMesh.meshLineIndex !== lastMeshLineIndex) {
        newMeshLineIndex++;
      }
      newMesh.meshLineIndex = newMeshLineIndex;

      this.meshLines.push(newMesh);

      lastMeshLineIndex = meshLine.meshLineIndex;
    }

    this.meshLines = meshLines;

    const firstMeshLine = meshLines[0];

    this.firstPoint = this.meshLines[0].start;

    let positiveSegmentDirection: boolean;
    if (firstMeshLine.end.x > firstMeshLine.start.x) {
      positiveSegmentDirection = true;
    }
    if (firstMeshLine.end.x === firstMeshLine.start.x) {
      if (firstMeshLine.end.y > firstMeshLine.start.y) {
        positiveSegmentDirection = true;
      } else {
        positiveSegmentDirection = false;
      }
    } else {
      positiveSegmentDirection = false;
    }

    lastMeshLineIndex = this.meshLines
      .map((e) => e.meshLineIndex)
      .reduce((a, b) => (a > b ? a : b));
    const firstMeshLineIndex = this.meshLines[0].meshLineIndex;

    const useStartPoint = (lastMeshLineIndex - firstMeshLineIndex) % 2 === 0;

    const pointsToConsiderToChoseLast = this.meshLines
      .filter((e) => e.meshLineIndex === lastMeshLineIndex)
      .map((e) => {
        if (useStartPoint) {
          return e.start;
        }
        return e.end;
      });

    this.lastPoint = pointsToConsiderToChoseLast.reduce((a, b) => {
      if (positiveSegmentDirection) {
        if (a.x > b.x) {
          return a;
        }
        if (a.x === b.x) {
          if (a.y > b.y) {
            return a;
          }
          return b;
        }
        return b;
      } else {
        if (a.x < b.x) {
          return a;
        }
        if (a.x === b.x) {
          if (a.y < b.y) {
            return a;
          }
          return b;
        }
        return b;
      }
    });
  }

  inOppositeDirection(): MeshLineSector {
    let meshLines: _MeshLine[] = [
      ...this.meshLines
        .filter((e) => e.meshLineIndex === 0)
        .map((e) =>
          e.copyWith({
            start: e.end,
            startPointPolygonSideIndex: e.endPointPolygonSideIndex,
            startAppliedPaddingAmount: e.endAppliedPaddingAmount,
            end: e.start,
            endPointPolygonSideIndex: e.startPointPolygonSideIndex,
            endAppliedPaddingAmount: e.startAppliedPaddingAmount,
          })
        ),
    ];
    meshLines.reverse();

    meshLines = [...meshLines, ...this.meshLines.filter((e) => e.meshLineIndex !== 0)];

    return new MeshLineSector(meshLines);
  }

  inOppositeOrder(): MeshLineSector {
    const meshLines = [...this.meshLines];

    meshLines.reverse();

    return new MeshLineSector(meshLines);
  }

  applyPadding(
    sides: {
      segment: Segment;
      index: number;
    }[],
    /// Necessary because we will not create again a padding polygon if it was already created
    createTrackWidthPaddingPolygon: (paddingAmount: number) => CartesianPoint[] | null
  ): MeshLineSector | null {
    const newMeshLines: _MeshLine[] = [];
    let hasChange: boolean = false;

    for (const meshLine of this.meshLines) {
      const paddingMeshLine = meshLine.applyPadding(
        sides,
        this.lastPoint,
        createTrackWidthPaddingPolygon
      );

      if (paddingMeshLine !== null) {
        hasChange = true;
      }

      newMeshLines.push(paddingMeshLine ?? meshLine.copyWith({}));
    }

    if (!hasChange) return null;

    return new MeshLineSector(newMeshLines);
  }

  getRoute(
    nextConnectionIsArbitrary: boolean
  ): Omit<CartesianPlannerRoutePoint, "meshSectorIndex">[] {
    const result = findMissionPlannerContinuosParallelRoute(this.meshLines);
    if (result.length === 0) return [];

    const lastPlannerPoint = result.pop()!;

    return [
      ...result,
      {
        ...lastPlannerPoint,
        polygonSectorsConnectionsToNotRelease: nextConnectionIsArbitrary,
      },
    ];
  }

  meshLineDistanceToLastPoint(meshLine: MeshLine): number {
    return [
      distanceBetweenPoints(this.lastPoint, meshLine.start),
      distanceBetweenPoints(this.lastPoint, meshLine.end),
    ].reduce((a, b) => (a < b ? a : b));
  }
}

/**
 * O(n) procedure
 */
const findMissionPlannerContinuosParallelRoute = (
  _missionPlannerMesh: MeshLine[]
): Omit<CartesianPlannerRoutePoint, "meshSectorIndex">[] => {
  let missionPlannerMesh = _missionPlannerMesh.map((e, index) => ({
    mesh: e,
    index: index,
  }));

  if (missionPlannerMesh.length === 0) return [];

  const firstMeshLineSegment = missionPlannerMesh.splice(0, 1)[0];
  let lastMeshLineIndex = firstMeshLineSegment.mesh.meshLineIndex;

  let hasMoreThanOneElementWithFirstMeshLineSegment =
    missionPlannerMesh.find((e) => e.mesh.meshLineIndex === lastMeshLineIndex) !== undefined;

  let missionPlannerRoute: Omit<CartesianPlannerRoutePoint, "meshSectorIndex">[] = [
    {
      ...firstMeshLineSegment.mesh.start,
      outsideSegment: false,
      polygonSectorsConnectionsToNotRelease: false,
      connectionUsingBorder: false,
    },
    {
      ...firstMeshLineSegment.mesh.end,
      polygonSectorsConnectionsToNotRelease: false,
      outsideSegment: false,
      connectionUsingBorder: false,
    },
  ];

  let usingYCoordinatesToFindLastRouteSegmentDirection = true;
  let lastRouteSegmentInPositiveDirection = false;

  const getLastRouteSegmentOrientation = () => {
    const lastPoint = missionPlannerRoute[missionPlannerRoute.length - 1];
    const penultimatePoint = missionPlannerRoute[missionPlannerRoute.length - 2];

    if (lastPoint.x != penultimatePoint.x) {
      usingYCoordinatesToFindLastRouteSegmentDirection = false;
      lastRouteSegmentInPositiveDirection = lastPoint.x > penultimatePoint.x;
    } else {
      usingYCoordinatesToFindLastRouteSegmentDirection = true;
      lastRouteSegmentInPositiveDirection = lastPoint.y > penultimatePoint.y;
    }
  };
  getLastRouteSegmentOrientation();

  const changeMeshLineOrientationToBeInTheNextRouteOrientation = (segment: MeshLine): MeshLine => {
    let reverse: boolean = false;

    /// We will continue the route, so we need a line keep the same orientation
    if (segment.meshLineIndex === lastMeshLineIndex) {
      if (usingYCoordinatesToFindLastRouteSegmentDirection) {
        /// We need start.y < end.y
        if (lastRouteSegmentInPositiveDirection) {
          if (segment.end.y < segment.start.y) {
            reverse = true;
          }
        }
        /// We need start.y > end.y
        else {
          if (segment.end.y > segment.start.y) {
            reverse = true;
          }
        }
      } else {
        /// We need start.x < end.x
        if (lastRouteSegmentInPositiveDirection) {
          if (segment.end.x < segment.start.x) {
            reverse = true;
          }
        }
        /// We need start.x > end.x
        else {
          if (segment.end.x > segment.start.x) {
            reverse = true;
          }
        }
      }
    }
    /// We need a line in the opposite orientation
    else {
      if (usingYCoordinatesToFindLastRouteSegmentDirection) {
        /// We need start.y > end.y
        if (lastRouteSegmentInPositiveDirection) {
          if (segment.end.y > segment.start.y) {
            reverse = true;
          }
        }
        /// We need start.y < end.y
        else {
          if (segment.end.y < segment.start.y) {
            reverse = true;
          }
        }
      } else {
        /// We need start.x > end.x
        if (lastRouteSegmentInPositiveDirection) {
          if (segment.end.x > segment.start.x) {
            reverse = true;
          }
        }
        /// We need start.x < end.x
        else {
          if (segment.end.x < segment.start.x) {
            reverse = true;
          }
        }
      }
    }

    const start = reverse ? segment.end : segment.start;
    const end = reverse ? segment.start : segment.end;

    return new _MeshLine(
      start,
      reverse ? segment.endPointPolygonSideIndex : segment.startPointPolygonSideIndex,
      end,
      reverse ? segment.startPointPolygonSideIndex : segment.endPointPolygonSideIndex,
      segment.meshLineIndex,
      segment.line,
      reverse ? segment.endAppliedPaddingAmount : segment.startAppliedPaddingAmount,
      reverse ? segment.startAppliedPaddingAmount : segment.endAppliedPaddingAmount,
      distanceBetweenPoints(start, end)
    );
  };

  const connectMeshLinesWithSameLineIndex = (
    _meshLines: MeshLine[]
  ): Omit<CartesianPlannerRoutePoint, "meshSectorIndex">[] => {
    if (_meshLines.length === 0) return [];

    const meshLines = _meshLines.map(changeMeshLineOrientationToBeInTheNextRouteOrientation);
    /// Here the start point of every mesh line is the start point if that one is choose

    if (meshLines.length === 1) {
      return [
        {
          ...meshLines[0].start,
          outsideSegment: false,
          polygonSectorsConnectionsToNotRelease: false,
          connectionUsingBorder: false,
        },
        {
          ...meshLines[0].end,
          outsideSegment: false,
          polygonSectorsConnectionsToNotRelease: false,
          connectionUsingBorder: false,
        },
      ];
    }

    meshLines.sort((a, b) => {
      if (usingYCoordinatesToFindLastRouteSegmentDirection) {
        if (lastRouteSegmentInPositiveDirection) {
          if (a.start.y > b.start.y) {
            return -1;
          }
          return 1;
        } else {
          if (a.start.y < b.start.y) {
            return -1;
          }
          return 1;
        }
      } else {
        if (lastRouteSegmentInPositiveDirection) {
          if (a.start.x > b.start.x) {
            return -1;
          }
          return 1;
        } else {
          if (a.start.x < b.start.x) {
            return -1;
          }
          return 1;
        }
      }
    });

    const result: Omit<CartesianPlannerRoutePoint, "meshSectorIndex">[] = [];

    for (let i = 0; i < meshLines.length; i++) {
      const meshLine = meshLines[i];

      const isLast = i === meshLines.length - 1;

      result.push({
        ...meshLine.start,
        outsideSegment: false,
        polygonSectorsConnectionsToNotRelease: false,
        connectionUsingBorder: false,
      });
      result.push({
        ...meshLine.end,
        outsideSegment: !isLast,
        polygonSectorsConnectionsToNotRelease: false,
        connectionUsingBorder: false,
      });
    }

    return result;
  };

  while (missionPlannerMesh.length > 0) {
    getLastRouteSegmentOrientation();

    missionPlannerMesh = missionPlannerMesh.map((e, index) => ({ mesh: e.mesh, index: index }));

    const actualConnectingMeshLineIndex = missionPlannerMesh[0].mesh.meshLineIndex;

    const missionPlannerMeshWithSameLineIndex = missionPlannerMesh.filter(
      (e) => e.mesh.meshLineIndex === actualConnectingMeshLineIndex
    );

    const newRouteUsingMeshLineIndex = connectMeshLinesWithSameLineIndex(
      missionPlannerMeshWithSameLineIndex.map((e) => e.mesh)
    );

    missionPlannerRoute = [...missionPlannerRoute, ...newRouteUsingMeshLineIndex];

    const usedMissionPlannerMeshItemsIndexes = missionPlannerMeshWithSameLineIndex.map(
      (e) => e.index
    );

    missionPlannerMesh = missionPlannerMesh.filter(
      (_, index) => !usedMissionPlannerMeshItemsIndexes.includes(index)
    );
  }

  return missionPlannerRoute;
};

const checkPointInsidePolygon = (p: CartesianPoint, polygon: CartesianPoint[]): boolean => {
  const biggestXValue = polygon.map((p) => p.x).reduce((a, b) => (a > b ? a : b));

  const testSegment: Segment = {
    p1: {
      x: p.x,
      y: p.y,
    },
    p2: {
      x: biggestXValue + 10,
      y: p.y,
    },
  };

  let intersections = 0;
  for (let i = 0; i < polygon.length; i++) {
    const sideSegment: Segment = {
      p1: polygon[i],
      p2: polygon[(i + 1) % polygon.length],
    };

    if (checkPointInSegment(p, sideSegment)) {
      return true;
    }

    if (checkSegmentIntersection(testSegment, sideSegment)) {
      intersections++;
    }
  }

  return intersections % 2 === 1;
};

const castGeolibPointToLocation = (point: { latitude: number; longitude: number }): Location => {
  return {
    lat: point.latitude,
    lng: point.longitude,
  };
};

const findIntersectionBetweenLineAndLineSegment = (
  line: CartesianLine,
  segment: {
    p1: CartesianPoint;
    p2: CartesianPoint;
  }
): CartesianPoint | undefined => {
  const x1 = segment.p1.x;
  const x2 = segment.p2.x;
  const y1 = segment.p1.y;
  const y2 = segment.p2.y;

  const a = line.equationA;
  const b = line.equationB;
  const c = line.equationC;

  const alphaDenominator = a * (x2 - x1) + b * (y2 - y1);

  if (alphaDenominator === 0) return undefined;

  const alpha = (-c - a * x1 - b * y1) / alphaDenominator;

  if (alpha < 0 || alpha > 1) return undefined;

  const xIntersection = x1 + alpha * (x2 - x1);
  const yIntersection = y1 + alpha * (y2 - y1);

  return {
    x: xIntersection,
    y: yIntersection,
  };
};

const polygonVerticesAsClockwise = (vertices: CartesianPoint[]): CartesianPoint[] => {
  const isClockWise = (): boolean => {
    // We use the winding of the polygon to discover its orientation.
    // https://www.element84.com/blog/determining-the-winding-of-a-polygon-given-as-a-set-of-ordered-points
    // I am unsure of what the relation is between this formula and the
    // shoelace formulat for calculating the area of a polygon.
    let winding = 0;
    for (let i = 0; i < vertices.length; i++) {
      const nextIndex = circularIndex(i + 1, vertices.length);

      const point = vertices[i];
      const nextPoint = vertices[nextIndex];

      winding += (nextPoint.x - point.x) * (nextPoint.y + point.y);
    }
    return winding > 0;
  };

  let result;
  if (!isClockWise()) {
    result = vertices.reverse();
  } else {
    result = vertices;
  }
  if (result.length > 1) {
    if (
      result[0].x === result[result.length - 1].x &&
      result[0].y === result[result.length - 1].y
    ) {
      result.pop();
    }
  }

  return result;
};

const circularIndex = (listIndex: number, listSize: number): number => {
  let index = listIndex;

  while (index < 0) {
    index = index + listSize;
  }

  return index % listSize;
};

const distanceBetweenPoints = (a: CartesianPoint, b: CartesianPoint): number => {
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
};

const getCartesianLineAngle = (line: CartesianLine): number => {
  if (line.equationA === 0) {
    return 0;
  }
  if (line.equationB === 0) {
    return 90;
  }

  const tan = -line.equationA / line.equationB;
  const atan = Math.atan(tan);

  return radiansAngleToAngle(atan);
};

const createCartesianPointsLineEquation = (a: CartesianPoint, b: CartesianPoint): CartesianLine => {
  if (a.x === b.x) {
    return {
      equationA: 1,
      equationB: 0,
      equationC: -a.x,
    };
  }

  /// Lets find the equation like y = ax + b
  const deltaX = b.x - a.x;
  const deltaY = b.y - a.y;
  const angularCoefficient = deltaY / deltaX;
  const independentTerm = a.y - angularCoefficient * a.x;

  /// Thats our equation: y = angularCoefficient * x + independentTerm
  /// We need something like ax + by + c = 0
  /// So:
  /// y = angularCoefficient * x + independentTerm
  /// <=> y - angularCoefficient * x - independentTerm = 0
  /// <=> - angularCoefficient * x + y - independentTerm = 0

  const unsignedAngle = radiansAngleToAngle(Math.atan(Math.abs(angularCoefficient)));

  let angle: number;
  if (deltaY > 0 && deltaX > 0) {
    angle = unsignedAngle;
  } else if (deltaY > 0) {
    angle = 180 - unsignedAngle;
  } else if (deltaX > 0) {
    angle = -unsignedAngle;
  } else {
    angle = unsignedAngle - 180;
  }

  return {
    equationA: -angularCoefficient,
    equationB: 1,
    equationC: -independentTerm,
  };
};

const applyPaddingInPolygonSide = (
  initial: CartesianPoint,
  final: CartesianPoint,
  paddingDistance: number,
  polygon: CartesianPoint[]
): {
  initial: CartesianPoint;
  final: CartesianPoint;
} => {
  const middlePoint: CartesianPoint = {
    x: (initial.x + final.x) / 2,
    y: (initial.y + final.y) / 2,
  };

  const sideVector: Vector = {
    deltaX: final.x - initial.x,
    deltaY: final.y - initial.y,
  };

  const directionVector = rotate90PositiveDegreesOnVector(sideVector);
  const directionVectorNorm = vectorNorm(directionVector);

  let paddingVector: Vector = {
    deltaX: (directionVector.deltaX / directionVectorNorm) * paddingDistance,
    deltaY: (directionVector.deltaY / directionVectorNorm) * paddingDistance,
  };

  if (
    !checkPointInsidePolygon(
      {
        x: middlePoint.x + paddingVector.deltaX,
        y: middlePoint.y + paddingVector.deltaY,
      },
      polygon
    )
  ) {
    paddingVector = {
      deltaX: -paddingVector.deltaX,
      deltaY: -paddingVector.deltaY,
    };
  }

  return {
    initial: {
      x: initial.x + paddingVector.deltaX,
      y: initial.y + paddingVector.deltaY,
    },
    final: {
      x: final.x + paddingVector.deltaX,
      y: final.y + paddingVector.deltaY,
    },
  };
};

const rotate90PositiveDegreesOnVector = (vector: Vector): Vector => {
  const x = vector.deltaX;
  const y = vector.deltaY;
  if (x >= 0 && y >= 0) {
    return {
      deltaX: y,
      deltaY: -x,
    };
  } else if (x >= 0) {
    return {
      deltaX: y,
      deltaY: -x,
    };
  } else if (y >= 0) {
    return {
      deltaX: y,
      deltaY: -x,
    };
  } else {
    return {
      deltaX: y,
      deltaY: -x,
    };
  }
};

const polygonArea = (polygon: CartesianPoint[]): number => {
  let sum = 0;

  for (let i = 0; i < polygon.length; i++) {
    const cast = (a: CartesianPoint): Vector => ({
      deltaX: a.x,
      deltaY: a.y,
    });
    const crossProduct = vectorsCrossProduct(
      cast(polygon[i]),
      cast(polygon[(i + 1) % polygon.length])
    );
    sum = sum + crossProduct;
  }

  return Math.abs(sum) / 2;
};

const getPointNearbyPolygonPointDistanceAndSide = (
  point: CartesianPoint,
  polygon: CartesianPoint[]
): {
  distance: number;
  nearbyPoint: CartesianPoint;
  side: number;
} => {
  let side = 0;
  let minDistanceAndNearbyPoint = distanceBetweenPointAndSegmentAndNearbyPoint(
    point,
    polygon[0],
    polygon[1]
  );

  for (let i = 1; i < polygon.length; i++) {
    const distanceAndNearbyPoint = distanceBetweenPointAndSegmentAndNearbyPoint(
      point,
      polygon[i],
      polygon[(i + 1) % polygon.length]
    );

    if (distanceAndNearbyPoint.distance < minDistanceAndNearbyPoint.distance) {
      minDistanceAndNearbyPoint = distanceAndNearbyPoint;
      side = i;
    }
  }

  return {
    ...minDistanceAndNearbyPoint,
    side: side,
  };
};

const distanceBetweenPointAndSegmentAndNearbyPoint = (
  p: CartesianPoint,
  a: CartesianPoint,
  b: CartesianPoint
): {
  distance: number;
  nearbyPoint: CartesianPoint;
} => {
  /// Method in https://www.youtube.com/watch?app=desktop&v=egmZJU-1zPU
  const ab: Vector = {
    deltaX: b.x - a.x,
    deltaY: b.y - a.y,
  };
  const ap: Vector = {
    deltaX: p.x - a.x,
    deltaY: p.y - a.y,
  };

  const proj = vectorsDotProduct(ap, ab);

  const abNorm = vectorNorm(ab);

  const d = proj / Math.pow(abNorm, 2);

  let cp: CartesianPoint;

  if (d < 0) {
    cp = a;
  } else if (d >= 1) {
    cp = b;
  } else {
    cp = {
      x: a.x + d * ab.deltaX,
      y: a.y + d * ab.deltaY,
    };
  }

  return {
    distance: distanceBetweenPoints(p, cp),
    nearbyPoint: cp,
  };
};

type Vector = {
  deltaX: number;
  deltaY: number;
};

const checkSegmentIntersection = (a: Segment, b: Segment): boolean => {
  if (a.p1 === b.p1 || a.p1 === b.p2 || a.p2 === b.p1 || a.p2 === b.p2) {
    return false;
  }

  /// Two line segments intersect iff each of the two pairs of cross products bellow have different signs
  /// (or one cross product in the pair is 0)
  /// (p1 - p4) x (p3 - p4) and (p2 - p4) x (p3 - p4)
  /// (p3 - p2) x (p1 - p2) and (p4 - p2) x (p1 - p2)

  const p1 = a.p2;
  const p2 = a.p1;
  const p3 = b.p2;
  const p4 = b.p1;

  /// a to b vector
  const segmentToVector = (a: CartesianPoint, b: CartesianPoint): Vector => {
    return {
      deltaX: b.x - a.x,
      deltaY: b.y - a.y,
    };
  };

  const cp1 = vectorsCrossProduct(segmentToVector(p1, p4), segmentToVector(p3, p4));
  const cp2 = vectorsCrossProduct(segmentToVector(p2, p4), segmentToVector(p3, p4));

  const cp3 = vectorsCrossProduct(segmentToVector(p3, p2), segmentToVector(p1, p2));
  const cp4 = vectorsCrossProduct(segmentToVector(p4, p2), segmentToVector(p1, p2));

  if (
    ((cp1 > 0 && cp2 < 0) || (cp1 < 0 && cp2 > 0)) &&
    ((cp3 > 0 && cp4 < 0) || (cp3 < 0 && cp4 > 0))
  ) {
    return true;
  }

  if ([cp1, cp2, cp3, cp4].find((e) => e === 0)) return true;

  return false;
};

const vectorsCrossProduct = (a: Vector, b: Vector): number => {
  return a.deltaX * b.deltaY - a.deltaY * b.deltaX;
};

const vectorsDotProduct = (a: Vector, b: Vector): number => {
  return a.deltaX * b.deltaX + a.deltaY * b.deltaY;
};

const vectorNorm = (vector: Vector): number => {
  return Math.sqrt(Math.pow(vector.deltaX, 2) + Math.pow(vector.deltaY, 2));
};

const angleToRadians = (angle: number): number => {
  return (angle * Math.PI) / 180;
};

const radiansAngleToAngle = (radians: number): number => {
  return (radians * 180) / Math.PI;
};

/// The input is an angle between [-180, 180] and the result is an angle between [0, 360]
const convertAngleToBearing = (angle: number): number => {
  if (angle < 90) {
    return 90 - angle;
  } else {
    return 90 + 360 - angle;
  }
};

/// The input is an angle between [0, 360] and the result is an angle between [-180, 180]
const convertBearingToAngle = (bearing: number): number => {
  if (bearing < 270) {
    return 90 - bearing;
  } else {
    return 90 + 360 - bearing;
  }
};

class _MeshLine {
  public start: CartesianPoint;
  public startPointPolygonSideIndex: number;
  startAppliedPaddingAmount: number;
  public end: CartesianPoint;
  public endPointPolygonSideIndex: number;
  endAppliedPaddingAmount: number;
  public meshLineIndex: number;
  line: CartesianLine;
  length: number;

  static fromType(meshLine: MeshLine): _MeshLine {
    return new _MeshLine(
      meshLine.start,
      meshLine.startPointPolygonSideIndex,
      meshLine.end,
      meshLine.endPointPolygonSideIndex,
      meshLine.meshLineIndex,
      meshLine.line,
      meshLine.startAppliedPaddingAmount,
      meshLine.endAppliedPaddingAmount,
      meshLine.length
    );
  }

  toType(): MeshLine {
    return {
      start: this.start,
      startPointPolygonSideIndex: this.startPointPolygonSideIndex,
      startAppliedPaddingAmount: this.startAppliedPaddingAmount,
      end: this.end,
      endPointPolygonSideIndex: this.endPointPolygonSideIndex,
      endAppliedPaddingAmount: this.endAppliedPaddingAmount,
      meshLineIndex: this.meshLineIndex,
      line: this.line,
      length: this.length,
    };
  }

  constructor(
    start: CartesianPoint,
    startPointPolygonSideIndex: number,
    end: CartesianPoint,
    endPointPolygonSideIndex: number,
    meshLineIndex: number,
    line: CartesianLine,
    startAppliedPaddingAmount: number,
    endAppliedPaddingAmount: number,
    length: number
  ) {
    this.start = start;
    this.end = end;
    this.startPointPolygonSideIndex = startPointPolygonSideIndex;
    this.endPointPolygonSideIndex = endPointPolygonSideIndex;
    this.meshLineIndex = meshLineIndex;
    this.line = line;
    this.length = length;
    this.startAppliedPaddingAmount = startAppliedPaddingAmount;
    this.endAppliedPaddingAmount = endAppliedPaddingAmount;
  }

  copyWith(parameters: {
    start?: CartesianPoint;
    end?: CartesianPoint;
    startAppliedPaddingAmount?: number;
    endAppliedPaddingAmount?: number;
    startPointPolygonSideIndex?: number;
    endPointPolygonSideIndex?: number;
  }): _MeshLine {
    let hasPointsChange = false;
    let startPoint = this.start;
    let endPoint = this.end;

    if (parameters.start !== undefined) {
      startPoint = parameters.start;
      hasPointsChange = true;
    }
    if (parameters.end !== undefined) {
      endPoint = parameters.end;
      hasPointsChange = true;
    }

    return new _MeshLine(
      startPoint,
      parameters.startPointPolygonSideIndex ?? this.startPointPolygonSideIndex,
      endPoint,
      parameters.endPointPolygonSideIndex ?? this.endPointPolygonSideIndex,
      this.meshLineIndex,
      this.line,
      this.startAppliedPaddingAmount,
      this.endAppliedPaddingAmount,
      hasPointsChange ? distanceBetweenPoints(startPoint, endPoint) : this.length
    );
  }

  canTryToApplyPadding(trackWidth: number): boolean {
    return this.length >= trackWidth;
  }

  applyPadding(
    sides: {
      segment: Segment;
      index: number;
    }[],
    pointToNoApplyPadding: CartesianPoint,
    /// Necessary because we will not create again a padding polygon if it was already created
    createTrackWidthPaddingPolygon: (paddingAmount: number) => CartesianPoint[] | null
  ): _MeshLine | null {
    let newStartPoint = this.start;
    let newStartAppliedPaddingAmount = this.startAppliedPaddingAmount;
    let newEndPoint = this.end;
    let newEndAppliedPaddingAmount = this.endAppliedPaddingAmount;

    const startSideToApplyPadding = sides.find((side) => {
      if (this.startPointPolygonSideIndex !== side.index) return false;

      const sideXValues = [side.segment.p1.x, side.segment.p2.x];
      const sideYValues = [side.segment.p1.y, side.segment.p2.y];

      const sideMinX = sideXValues.reduce((a, b) => (a < b ? a : b));
      const sideMaxX = sideXValues.reduce((a, b) => (a > b ? a : b));
      const sideMinY = sideYValues.reduce((a, b) => (a < b ? a : b));
      const sideMaxY = sideYValues.reduce((a, b) => (a > b ? a : b));

      if (
        this.start.x < sideMinX ||
        this.start.x > sideMaxX ||
        this.start.y < sideMinY ||
        this.start.y > sideMaxY
      ) {
        return false;
      }

      return true;
    });

    const endSideToApplyPadding = sides.find((side) => {
      if (this.endPointPolygonSideIndex !== side.index) return false;

      const sideXValues = [side.segment.p1.x, side.segment.p2.x];
      const sideYValues = [side.segment.p1.y, side.segment.p2.y];

      const sideMinX = sideXValues.reduce((a, b) => (a < b ? a : b));
      const sideMaxX = sideXValues.reduce((a, b) => (a > b ? a : b));
      const sideMinY = sideYValues.reduce((a, b) => (a < b ? a : b));
      const sideMaxY = sideYValues.reduce((a, b) => (a > b ? a : b));

      if (
        this.end.x < sideMinX ||
        this.end.x > sideMaxX ||
        this.end.y < sideMinY ||
        this.end.y > sideMaxY
      ) {
        return false;
      }

      return true;
    });

    if (startSideToApplyPadding !== undefined && pointToNoApplyPadding !== this.start) {
      const paddingPolygon = createTrackWidthPaddingPolygon(this.startAppliedPaddingAmount + 1);
      if (paddingPolygon !== null) {
        const startSide: Segment = {
          p1: paddingPolygon[this.startPointPolygonSideIndex],
          p2: paddingPolygon[(this.startPointPolygonSideIndex + 1) % paddingPolygon.length],
        };

        const intersection = findIntersectionBetweenLineAndLineSegment(this.line, startSide);
        if (
          intersection !== undefined &&
          intersection !== newStartPoint &&
          this.pointInXInterval(intersection) &&
          this.pointInYInterval(intersection)
        ) {
          newStartPoint = intersection;
          newStartAppliedPaddingAmount++;
        }
      }
    }

    if (endSideToApplyPadding !== undefined && pointToNoApplyPadding !== this.end) {
      const paddingPolygon = createTrackWidthPaddingPolygon(this.endAppliedPaddingAmount + 1);
      if (paddingPolygon !== null) {
        const endSide: Segment = {
          p1: paddingPolygon[this.endPointPolygonSideIndex],
          p2: paddingPolygon[(this.endPointPolygonSideIndex + 1) % paddingPolygon.length],
        };

        const intersection = findIntersectionBetweenLineAndLineSegment(this.line, endSide);
        if (
          intersection !== undefined &&
          intersection !== newEndPoint &&
          this.pointInXInterval(intersection) &&
          this.pointInYInterval(intersection)
        ) {
          newEndPoint = intersection;
          newEndAppliedPaddingAmount++;
        }
      }
    }

    if (newStartPoint === this.start && newEndPoint === this.end) return null;

    return this.copyWith({
      start: newStartPoint,
      end: newEndPoint,
      startAppliedPaddingAmount: newStartAppliedPaddingAmount,
      endAppliedPaddingAmount: newEndAppliedPaddingAmount,
    });
  }

  pointInXInterval(p: CartesianPoint): boolean {
    const values = [this.start.x, this.end.x];

    const minValue = values.reduce((a, b) => (a < b ? a : b));
    const maxValue = values.reduce((a, b) => (a > b ? a : b));

    if (p.x < minValue || p.x > maxValue) {
      return false;
    }

    return true;
  }

  pointInYInterval(p: CartesianPoint): boolean {
    const values = [this.start.y, this.end.y];

    const minValue = values.reduce((a, b) => (a < b ? a : b));
    const maxValue = values.reduce((a, b) => (a > b ? a : b));

    if (p.y < minValue || p.y > maxValue) {
      return false;
    }

    return true;
  }
}

const checkPointInSegment = (
  p: CartesianPoint,
  segment: {
    p1: CartesianPoint;
    p2: CartesianPoint;
  }
): boolean => {
  const x1 = segment.p1.x;
  const x2 = segment.p2.x;
  const y1 = segment.p1.y;
  const y2 = segment.p2.y;

  const x = p.x;
  const y = p.y;

  const alpha = (x - x1) / (x2 - x1);
  if (alpha < 0 || alpha > 1) return false;

  const yUsingAlpha = y1 + alpha * (y2 - y1);

  return yUsingAlpha === y;
};

type Segment = {
  p1: CartesianPoint;
  p2: CartesianPoint;
};
